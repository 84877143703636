<template>
  <div class="roleEdit" v-if="hasResource('admin_role_edit') || hasResource('admin_role_add')">
    <div class="pd-tb-82-lr-92" v-loading="submitLoading">
        <div class="fz-18 cursor-pointer width-107 height-46 mg-b-20 text-back" @click="back">
            <i class="iconfont fz-18 black">&#xe647;</i>
            <span class="back-text">返回</span>
        </div>
          <div class="form-container">
            <el-row :gutter="24" class>
              <el-col :span="3">
                <div v-if="!editRoleId" class="fz-24 fw-700 pd-l-35 pd-t-30">新增角色</div>
                <div v-if="editRoleId" class="fz-24 fw-700 pd-l-35 pd-t-30">编辑角色</div>
              </el-col>
            </el-row>
            <el-row :gutter="24" class="pd-t-30">
              <el-form :inline="true" :rules="formRules" ref="form" :model="form">
                <el-col :span="10" class="role-name">
                  <el-form-item label="角色名称" prop="role_name">
                    <el-input
                      v-model="form.role_name"
                      placeholder="输入角色名称"
                      clearable
                    />
                  </el-form-item>
                </el-col>
              </el-form>
            </el-row>
            <div v-if="editRoleId">
              <el-row :gutter="24" class="pd-t-30">
                <el-col :span="3">
                  <div class="pd-t-14 pd-l-35 fz-18 fw-500">
                    <label>角色成员 {{ form.roleUsers.length }}</label>
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="24" class="pd-t-10">
                <el-col :span="24">
                  <div class="pd-t-14 pd-l-35 fz-14">
                    <span
                      class="fz-14"
                      v-for="(item, index) in form.roleUsers"
                      :key="index"
                    >
                      {{ item.username }}
                      <span v-if="index != form.roleUsers.length - 1">、</span>
                    </span>
                  </div>
                </el-col>
              </el-row>
            </div>
            <el-row :gutter="24" class="pd-t-40">
              <el-col :span="3">
                <div class="pd-t-14 pd-l-35 fz-18 fw-500">
                  <label>权限设置</label>
                </div>
              </el-col>
              <el-col :span="12" class="tree-container">
                <div class="resource pd-30" v-loading="treeLoading">
                  <el-checkbox
                    class="pd-l-10"
                    v-model="checkAll"
                    @change="handleCheckAllChange"
                    >全选</el-checkbox
                  >
                  <el-tree
                    :data="data"
                    show-checkbox
                   :check-strictly="checkStrictly"
                    default-expand-all
                    node-key="id"
                    ref="tree"
                    highlight-current
                    :props="defaultProps"
                    @check-change="checkChange"
                  ></el-tree>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="action-container pop-dialog mg-t-10">
            <div class="float-right">
              <el-button @click="submit" class="confirm-button"  :loading="btnLoading">确 认</el-button>
              <el-button @click="back" class="cancel-button">取 消</el-button>
            </div>
          </div>
    </div>
  </div>
</template>

<script>
import api from "../../../api/role";
import role from "../../../mixins/role";
export default {
  components: {},
  mixins: [role],
  data() {
    return {
      data: [],
      allResourcesKey: [],
      defaultProps: {
        children: "children",
        label: "title",
      },
      treeLoading: false,
      submitLoading: false,
      header: {
        url: "roleManagement",
      },
      checkStrictly: true,
      editRoleId: "",
      form: {
        roleUsers: [],
        resource: [],
        role_name: "",
      },
      checkAll: false,
    };
  },
  methods: {
    init() {
      this.getRoleResource();
      this.setCheckedKeys();
      this.getCurrentRoleId();
      this.getCurrentRoleResource();
    },
    getRoleResource() {
      this.treeLoading = true;
      api.getAdminRoleResource().then((res) => {
        if (res.data.code === 10200) {
          this.data = this.resolveRoleData(res.data.data);
          this.treeLoading = false;
          this.getAllResourceKeys(this.data);
        }
      });
    },
    resolveRoleData(data,parent = "") {
      let key = {};
      let res = [];
      for (key in data) {
        let resource = data[key];
        resource["parent"] = parent;
        if (resource["children"] != undefined && resource["children"] != []) {
          let resourceChildren = this.resolveRoleData(resource["children"],resource["id"]);
          resource["children"] = resourceChildren;
        }
        res.push(resource);
      }
      return res;
    },
    setCheckedKeys() {
      this.$refs.tree.setCheckedKeys(this.form.resource);
    },
    checkChange(e, checked) {
      if (checked) {
        let checkedKeys = this.$refs.tree.getCheckedKeys();
        if (checkedKeys.length == this.allResourcesKey.length) {
          this.checkAll = true;
        }
        this.$refs.tree.setChecked(e.parent,true);
      }else{
        if(this.checkAll){
          this.checkAll = false;
        }
        let children = e.children;
        this.cancelChildren(children);
      }
    },
    cancelChildren(children){
      let key = {};
      for (key in children) {
        let item = children[key];
        this.$refs.tree.setChecked(item.id,false);
        if(item.children){
          this.cancelChildren(item.children);
        }
      }
    },
    handleCheckAllChange() {
      if (this.checkAll) {
        this.$refs.tree.setCheckedKeys(this.allResourcesKey);
      } else {
        this.$refs.tree.setCheckedKeys([]);
      }
    },
    submit() {
      this.validForm(this.RoleCreateConfirm);
    },
    RoleCreateConfirm() {
      let checkedKeys = this.$refs.tree.getCheckedKeys();
      let params = {
        role_name: this.form.role_name,
        resources: checkedKeys,
        full_permission: this.checkAll,
      };
      this.submitLoading = true;
      if (this.editRoleId) {
        params.role_id = this.editRoleId;
        api.updateAdminRole(params).then((res) => {
          if (res.data.code === 10200) {
            this.$$success("成功");
            this.back();
          } else {
            this.$$error(res.data.message);
          }
          this.submitLoading = false;
        });
      } else {
        api.createAdminRole(params).then((res) => {
          if (res.data.code === 10200) {
            this.$$success("成功");
            this.back();
          } else {
            this.$$error(res.data.message);
          }
          this.submitLoading = false;
        });
      }
    },
    back() {
      this.$router.push('/settings/roles');
      this.msgBus.$emit('roleList')
    },
    getCurrentRoleId() {
      let query = this.$route.query;
      this.editRoleId = query.role_id;
    },
    getCurrentRoleResource() {
      let roleId = this.editRoleId;
      if (!roleId) {
        return;
      }
      api.adminRoleInfo(roleId).then((res) => {
        if (res.data.code === 10200) {
          this.form = {
            resource: res.data.data.resources,
            roleUsers: res.data.data.users,
            role_name: res.data.data.role_name,
          };

          this.setCheckedKeys();
          if (res.data.data.full_permission) {
            this.checkAll = true;
            this.handleCheckAllChange();
          }
        }
      });
    },
    getAllResourceKeys(data) {
      let keys = [];
      let key = {};
      for (key in data) {
        let resource = data[key];
        this.allResourcesKey.push(resource["id"]);
        if (resource["children"] != undefined && resource["children"] != []) {
          let resourceChildren = this.getAllResourceKeys(resource["children"]);
        }
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="less">
@import "scss/roleEdit.less";
</style>

import input from '../uitls/inputCheck';
export default {
  data() {
    var checkhasEmoji = (rule, value, callback) => {
      setTimeout(() => {
        const res = input.checkhasEmoji(value);
        const resSpace = input.checkIsSpace(value);
        if (res !== -1 || resSpace !== -1) {
          let error = (res !== -1) ? res : resSpace;
          callback(input.checkFormErrorMsg(error));
        }
        callback();
      }, 300);
    };
    return {
        form:{},
        formRules: {
            role_name: [
                { required: true, message: '请输入角色名称', trigger: 'blur' },
                { min: 0, max: 30, message: '请输入角色名称，不超过30个汉字', trigger: 'blur' },
                { validator: checkhasEmoji, trigger: 'blur' }
            ]
        }
    };
  },
  methods: {
    validForm(callback){
      this.$refs["form"].validate((valid) => {
        if(valid){
          callback();
        };
      });
    }
  }
};